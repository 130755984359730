import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button, Flex, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'

import React, { FC, useContext, useEffect } from 'react'

import Navbar from '~/components/Navbar'
import { withPage } from '~/components/page'
import { AuthContext } from '~/components/page/context'
import Reports from '~/components/reports/Reports'

const ReportsPage: FC = () => {
	const currentUser = useContext(AuthContext)

	useEffect(() => {
		if (currentUser.role?.name !== 'Admin') {
			navigate('/')
		}
	}, [currentUser])

	const onBack = async () => {
		return navigate(-1)
	}

	return (
		<Flex h='full' direction='column' background='#F2F2F2'>
			<Navbar />
			<Flex bg='#F2F2F2' height='3em' alignItems='center' px={12} justifyContent='space-between'>
				<Flex>
					<Button
						title='Back'
						aria-label='Edit'
						bg='transparent'
						color='black'
						marginRight='10px'
						_hover={{ bg: 'transparent', color: '#EEAB7E' }}
						leftIcon={<ArrowBackIcon sx={{ width: '20px' }} />}
						size='m'
						onClick={onBack}
					>
						Back
					</Button>
					<Text fontSize='1em' color='gray.600' lineHeight={1} marginTop='1px'>
						Reports
					</Text>
				</Flex>
			</Flex>
			<Reports />
		</Flex>
	)
}

export default withPage(ReportsPage, {
	public: false,
	name: 'Reports',
})
