import { gql, useQuery } from '@apollo/client'
import {
	Box,
	Flex,
	FormControl,
	HStack,
	Skeleton,
	Spinner,
	Switch,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { MultiValue } from 'react-select'

import React, { useEffect, useState } from 'react'

import { onError } from '~/components/helpers'
import {
	FiltersType,
	getLocalStorageReportFilters,
	StoredReportFilters,
} from '~/components/kanban/helpers'
import {
	LeadsAnalytics,
	LeadsAnalytics_leadsAnalytics_data,
	LeadsAnalyticsVariables,
} from '~/components/reports/__generated__/LeadsAnalytics'
import SelectDropdown, { CustomValueContainer } from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { Inquiries, Inquiries_inquiries } from '~/inputs/__generated__/Inquiries'
import { inquiryListQuery } from '~/inputs/inquiries'

export const leadsAnalytics = gql`
	query LeadsAnalytics($where: LeadsAnalyticsFilters) {
		leadsAnalytics(where: $where) {
			data {
				date
				fleet_street {
					np_target
					wk1
					wk2
					wk3
					wk4
					wk5
					np_actual
					to_target
				}
				manchester {
					np_target
					wk1
					wk2
					wk3
					wk4
					wk5
					np_actual
					to_target
				}
				notting_hill {
					np_target
					wk1
					wk2
					wk3
					wk4
					wk5
					np_actual
					to_target
				}
				wk1Total
				wk2Total
				wk3Total
				wk4Total
				wk5Total
				npActualTotal
				toTargetTotal
				npTargetTotal
			}
		}
	}
`

interface ClinicItem {
	name: 'Fleet Street' | 'Manchester' | 'Notting Hill'
	id: 'fleet_street' | 'manchester' | 'notting_hill'
}

const clinicsConfig: ClinicItem[] = [
	{
		name: 'Fleet Street',
		id: 'fleet_street',
	},
	{
		name: 'Manchester',
		id: 'manchester',
	},
	{
		name: 'Notting Hill',
		id: 'notting_hill',
	},
]

const LeadAnalyticsTab = () => {
	const [filterValue, setFilterValue] = useState(() => getLocalStorageReportFilters())

	const { data, loading, refetch } = useQuery<LeadsAnalytics, LeadsAnalyticsVariables>(
		leadsAnalytics,
		{
			variables: { where: filterValue },
		}
	)
	const toast = useToast()
	const inquiriesListQueryResult = useQuery<Inquiries>(inquiryListQuery, {
		onError: error => onError(error, toast),
	})

	useEffect(() => {
		refetch({
			where: filterValue,
		})
	}, [filterValue])

	const columnStyle = { padding: '0.5em 1em', verticalAlign: 'middle' }

	const renderTable = (tableData: LeadsAnalytics_leadsAnalytics_data) => {
		const renderClinicRow = (clinic: ClinicItem) => {
			return (
				<Tr
					sx={{
						cursor: 'pointer',
						'&:hover': {
							background: '#f9f9f9',
						},
					}}
				>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' fontWeight='bold' color='gray.600'>
							{clinic.name}
						</Text>
					</Td>
					<Td sx={{ ...columnStyle, background: '#8ae4ad' }}>
						<Text fontSize='0.8em' color='gray.800'>
							{tableData[clinic.id].np_target}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{tableData[clinic.id].wk1}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{tableData[clinic.id].wk2}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{tableData[clinic.id].wk3}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{tableData[clinic.id].wk4}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{tableData[clinic.id].wk5}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{tableData[clinic.id].np_actual}
						</Text>
					</Td>
					<Td
						sx={{
							...columnStyle,
							background: tableData[clinic.id].to_target <= 0 ? '#8ae4ad' : '#e48a8a',
						}}
					>
						<Text fontSize='0.8em' color='gray.800'>
							{tableData[clinic.id].to_target}
						</Text>
					</Td>
				</Tr>
			)
		}

		return (
			<TableContainer
				sx={{
					maxHeight: '100%',
					position: 'relative',
				}}
			>
				<Table variant='simple'>
					<Thead
						sx={{
							th: {
								position: 'sticky',
								top: 0,
								zIndex: 10,
								background: '#F2F2F2',
								padding: '1em',
							},
						}}
					>
						<Tr>
							<Th>{tableData.date}</Th>
							<Th>NP Target</Th>
							<Th>Wk1 Actual</Th>
							<Th>Wk2 Actual</Th>
							<Th>Wk3 Actual</Th>
							<Th>Wk4 Actual</Th>
							<Th>Wk5 Actual</Th>
							<Th>NP Actual</Th>
							<Th>To Target</Th>
						</Tr>
					</Thead>
					<Tbody
						sx={{
							overflow: 'scroll',
							height: 'auto',
						}}
					>
						{clinicsConfig.map(clinic => {
							return <React.Fragment key={clinic.id}>{renderClinicRow(clinic)}</React.Fragment>
						})}
						<Tr
							sx={{
								cursor: 'pointer',
								'&:hover': {
									background: '#f9f9f9',
								},
							}}
						>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' fontWeight='bold' color='gray.600'>
									Total
								</Text>
							</Td>
							<Td sx={{ ...columnStyle, background: '#8ae4ad' }}>
								<Text fontSize='0.8em' color='gray.800' fontWeight={700}>
									{tableData.npTargetTotal}
								</Text>
							</Td>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' color='gray.600' fontWeight={700}>
									{tableData.wk1Total}
								</Text>
							</Td>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' color='gray.600' fontWeight={700}>
									{tableData.wk2Total}
								</Text>
							</Td>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' color='gray.600' fontWeight={700}>
									{tableData.wk3Total}
								</Text>
							</Td>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' color='gray.600' fontWeight={700}>
									{tableData.wk4Total}
								</Text>
							</Td>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' color='gray.600' fontWeight={700}>
									{tableData.wk5Total}
								</Text>
							</Td>
							<Td sx={columnStyle}>
								<Text fontSize='0.8em' color='gray.600' fontWeight={700}>
									{tableData.npActualTotal}
								</Text>
							</Td>
							<Td
								sx={{
									...columnStyle,
									background: tableData.toTargetTotal <= 0 ? '#8ae4ad' : '#e48a8a',
								}}
							>
								<Text fontSize='0.8em' color='gray.800' fontWeight={700}>
									{tableData.toTargetTotal}
								</Text>
							</Td>
						</Tr>
					</Tbody>
				</Table>
			</TableContainer>
		)
	}

	const handleFilterChange = (args: StoredReportFilters) => {
		const finalFilters: FiltersType = {
			...filterValue,
			...args,
		}

		localStorage.setItem('report_filters', JSON.stringify(finalFilters))
		setFilterValue(finalFilters)
	}

	if (loading) {
		return (
			<Flex justify='center' gap='1em' p='1em' minHeight='500px'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	return (
		<Box>
			<Flex w='full' flexWrap='wrap' columnGap='0.4em' p={2} backgroundColor='#e4e2e2' zIndex={20}>
				<FormControl w='12em'>
					{inquiriesListQueryResult.loading ? (
						<HStack w='11em' h='2.3em'>
							<Skeleton w='full' h='full' rounded='md' />
						</HStack>
					) : (
						<SelectDropdown
							placeholder='Enquiries'
							hideSelectedOptions={false}
							variant='small'
							isClearable
							isMulti
							options={inquiriesListQueryResult.data?.inquiries as Inquiries_inquiries[]}
							defaultValue={
								inquiriesListQueryResult.data?.inquiries?.filter(inquiry =>
									filterValue?.inquiries?.includes(inquiry?.id || '')
								) as Inquiries_inquiries[]
							}
							getOptionLabel={opt => opt.name as string}
							getOptionValue={opt => opt.id}
							components={{
								ValueContainer: CustomValueContainer({
									selectedLabel: ['inquiries', 'inquiry'],
								}),
							}}
							onChange={selected => {
								const values = selected as MultiValue<Inquiries_inquiries>

								if (!values.length) {
									handleFilterChange({ inquiries: null })
									return
								}

								handleFilterChange({ inquiries: values.map(val => val.id) })
							}}
						/>
					)}
				</FormControl>
				<FormControl w='10em'>
					<Flex bg='white' px={4} py={2} rounded='md' shadow='sm' align='center' gap={2}>
						<Switch
							onChange={evt => {
								handleFilterChange({ canceled: evt.target.checked })
							}}
							colorScheme='twitter'
							defaultChecked={filterValue.canceled}
						/>
						<Text fontSize='0.8em'>Canceled/FTA</Text>
					</Flex>
				</FormControl>
			</Flex>
			{data?.leadsAnalytics?.data.map((tableData, index) => {
				return (
					<Flex key={index} direction='column' background='white' p='1em' mb='1em'>
						{renderTable(tableData)}
					</Flex>
				)
			})}
		</Box>
	)
}

export default LeadAnalyticsTab
