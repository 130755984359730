import { gql, useQuery } from '@apollo/client'
import {
	Box,
	Flex,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import moment from 'moment/moment'

import React, { useEffect } from 'react'

import DatePickerInput from '~/components/DatePicker'
import {
	LeadsConversionAnalytics,
	LeadsConversionAnalytics_leadsConversionAnalytics_data,
	LeadsConversionAnalyticsVariables,
} from '~/components/reports/__generated__/LeadsConversionAnalytics'

export const leadsConversionAnalyticsQuery = gql`
	query LeadsConversionAnalytics($date: String!) {
		leadsConversionAnalytics(date: $date) {
			data {
				username
				number_booked_in_diary
				number_fta
				number_cancelled
				number_completed
				number_booked
				tco_conversion
				amount_proposed
				amount_booked
				avg_tx_plan_proposed
			}
		}
	}
`

const LeadConversionAnalyticsTab = () => {
	const { data, loading, refetch } = useQuery<
		LeadsConversionAnalytics,
		LeadsConversionAnalyticsVariables
	>(leadsConversionAnalyticsQuery, {
		variables: { date: '2024-05' },
	})

	const formik = useFormik({
		initialValues: {
			date: new Date().toDateString(),
		},
		validateOnChange: true,
		onSubmit: () => refetch({ date: moment(formik.values.date).format('YYYY-MM') }),
	})

	useEffect(() => {
		if (formik.values.date) {
			refetch({ date: moment(formik.values.date).format('YYYY-MM') })
		}
	}, [formik.values.date])

	const prettifyValue = (value: number | null) => {
		if (!value) {
			return 'No data'
		}
		return Number.isInteger(value) ? value : value.toFixed(2)
	}

	const renderTable = (
		tableData: (LeadsConversionAnalytics_leadsConversionAnalytics_data | null)[] | undefined
	) => {
		if (!tableData) return null
		const columnStyle = { padding: '0.5em 1em', verticalAlign: 'middle' }

		const renderRow = (data: LeadsConversionAnalytics_leadsConversionAnalytics_data) => {
			return (
				<Tr
					sx={{
						cursor: 'pointer',
						'&:hover': {
							background: '#f9f9f9',
						},
					}}
				>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' fontWeight='bold' color='gray.600'>
							{data.username}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.800'>
							{data.number_booked_in_diary}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{data.number_fta}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{data.number_cancelled}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{data.number_completed}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{data.number_booked}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{prettifyValue(data.tco_conversion)}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{prettifyValue(data.amount_proposed)}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{prettifyValue(data.amount_booked)}
						</Text>
					</Td>
					<Td sx={columnStyle}>
						<Text fontSize='0.8em' color='gray.600'>
							{prettifyValue(data.avg_tx_plan_proposed)}
						</Text>
					</Td>
				</Tr>
			)
		}
		if (!tableData.length) {
			return (
				<Flex justify='center' gap='1em' p='1em' minHeight='500px'>
					<Text color='gray.500' fontSize='sm'>
						No Data
					</Text>
				</Flex>
			)
		}

		return (
			<TableContainer
				sx={{
					maxHeight: '100%',
					position: 'relative',
				}}
			>
				<Table variant='simple'>
					<Thead
						sx={{
							th: {
								position: 'sticky',
								top: 0,
								zIndex: 10,
								background: '#F2F2F2',
								padding: '1em',
							},
						}}
					>
						<Tr>
							<Th>User</Th>
							<Th>Booked in diary</Th>
							<Th>FTA</Th>
							<Th>Cancelled</Th>
							<Th>Completed</Th>
							<Th>Booked</Th>
							<Th>TCO Conversion %</Th>
							<Th>Amount Proposed</Th>
							<Th>Amount Booked</Th>
							<Th>Avg TX Plan Proposed</Th>
						</Tr>
					</Thead>
					<Tbody
						sx={{
							overflow: 'scroll',
							height: 'auto',
						}}
					>
						{tableData.map(data =>
							renderRow(data as LeadsConversionAnalytics_leadsConversionAnalytics_data)
						)}
					</Tbody>
				</Table>
			</TableContainer>
		)
	}

	if (loading) {
		return (
			<Flex justify='center' gap='1em' p='1em' minHeight='500px'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<Flex
					w='full'
					flexWrap='wrap'
					columnGap='0.4em'
					p={2}
					backgroundColor='#e4e2e2'
					zIndex={20}
				>
					<Flex
						align='center'
						sx={{
							'.chakra-input': {
								background: '#fff !important',
								color: '#000 !important',
							},
							'.react-datepicker__month-text--selected': {
								color: '#000 !important',
								background: '#eee',
								borderRadius: '2px',
							},
							'.react-datepicker__month-text:hover': {
								color: '#515151 !important',
							},
							'> div': {
								zIndex: 20,
							},
						}}
					>
						<DatePickerInput
							name='date'
							dateFormat='MMM yyyy'
							showMonthYearPicker
							showFullMonthYearPicker
							defaultValue={formik.values.date}
							required={true}
							onChange={value => formik.setFieldValue('date', value)}
						/>
					</Flex>
				</Flex>
			</form>
			<Flex direction='column' background='white' p='1em' mb='1em'>
				{renderTable(data?.leadsConversionAnalytics?.data)}
			</Flex>
		</Box>
	)
}

export default LeadConversionAnalyticsTab
